@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/Mukta/Mukta-ExtraLight.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/Mukta/Mukta-Light.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Mukta/Mukta-Regular.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/Mukta/Mukta-Medium.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Mukta/Mukta-SemiBold.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Mukta/Mukta-Bold.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/Mukta/Mukta-ExtraBold.ttf);
}

@font-face {
	font-family: IM_Fell_English_Italic;
	src: url(/fonts/IM+FELL+English+Italic.woff2);
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: 16px * $ratio;
}

@media only screen and (min-width: 0px) and (max-width : 722px)  {
  #mc_embed_signup {
    padding: 40px 15px 40px 15px !important;
  }
  #mce-EMAIL {
    margin-bottom: 10px;
    margin-right: 0 !important;
    text-align:center;
  }
  #mc-embedded-subscribe-form > h1 {
    margin-bottom: 10px;
  }
  #email-input {
    margin: 20px 0px !important;
  }
  div.duesLevels {
    left: 0 !important;
    width: 100% !important;
  }
  #link-nav {
    display: none;
  }
  #select-nav {
    display: block !important;
  }
  #current-selection {
    display: block !important;
  }
  #IWWlogo {
    margin-top: 20px !important;
  }
  #events-main-info {
    width: default !important;
    padding-left: 0px !important;
    padding-top: 15px;
  }
  .date-box {
    float: none !important;
  }
  .about-image {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 0px) and (max-width : 715px)  {
  .pp-selection {
    height: 220px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width : 350px)  {
  .pp-selection {
    height: 275px !important;
  }
}

.titleIWW {
  font-family: 'Mukta', sans-serif;
  font-weight: 700;
  letter-spacing: 7px;
  text-transform: uppercase;
  line-height: 1.8em;
  font-size: 16px;
  color: hsl(0, 0%, 29%);
  text-align:center;
  margin-top:26px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.next-meeting-header {
  text-align: center;
  background-color: rgba(34,34,34,1);
  color: #FFFFFF;
  padding: 8px;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
}

.current {
  color: hsl(356, 68%, 41%) !important;
}

#dropdown-content {
    display: none;
    position: absolute;
}

#dropdown-content a {
    display: block;
    z-index: 1;
    background-color: rgba(252, 252, 252, 0.9);
    padding-left: 8px;
    padding-right: 8px;
    text-align: left;
}

#dropdown:hover #dropdown-content {
  display: block;
}

#dropdown {
      display: inline-block;
}

.button-large {
  font-family: 'Mukta', sans-serif;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	-webkit-transition-duration: 0.22s; /* Safari */
	transition-duration: 0.22s;
	background-color: transparent;
	color: rgba(39,39,39,1);
	border: 2px solid rgba(39,39,39,1);
	margin:0 auto;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 17px 36px 17px 36px;
  cursor: pointer;
}

.button-large:hover {
	background-color: rgba(39,39,39,1);
	color: white;
  -webkit-transition-duration: 0.12s; /* Safari */
	transition-duration: 0.12s;

  font-family: 'Mukta', sans-serif;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	border: 2px solid rgba(39,39,39,1);
	margin:0 auto;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 17px 36px 17px 36px;
  cursor: pointer;
}

.button-small {
  font-family: 'Mukta', sans-serif;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 12px;
	margin: 4px 2px;
	-webkit-transition-duration: 0.22s; /* Safari */
	transition-duration: 0.22s;
	background-color: transparent;
	color: rgba(39,39,39,1);
	border: 2px solid rgba(39,39,39,1);
	margin:0 auto;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 13px 26px 13px 26px;
  cursor: pointer;
}

.button-small:hover {
	background-color: rgba(39,39,39,1);
	color: white;
  -webkit-transition-duration: 0.12s; /* Safari */
	transition-duration: 0.12s;
}

.button-newsletter {
  font-family: 'Mukta', sans-serif;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	-webkit-transition-duration: 0.22s; /* Safari */
	transition-duration: 0.22s;
	background-color: transparent;
	color: rgba(39,39,39,1);
	border: 2px solid rgba(39,39,39,1);
	margin:0 auto;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 12px 36px 11px 36px;
  cursor: pointer;
}

.button-newsletter:hover {
	background-color: rgba(39,39,39,1);
	color: white;
  -webkit-transition-duration: 0.12s; /* Safari */
	transition-duration: 0.12s;
}

hr {
  border:.5px solid #BBBBBB;
}

.page-title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 35px;
  line-height: 1;
}

.social-icons {
  margin-left: auto;
  margin-right: auto;
}

.social-icon {
  width: 50px;
  height: 50px;
  fill: #000000;
}

.social-icons:hover > a {
  opacity: 0.3;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.social-icons:hover a:hover {
    opacity: 1.0 !important;
}

ul li {
  margin-bottom: 12px;
}

ol li {
  margin-bottom: 12px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.date-box {
  float: left;
  margin-right: 35px;
  background: #E8ECEC;
  color: #424242;
  height: 72px;
  width: 72px;
  text-align: center
  ;display: flex;
  justify-content: center;
  align-items: center;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: rgba(33,33,33,1);
  padding-bottom: 10px;
}

/**
 * Basic styling
 */
body {
  font-family: 'Mukta', sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  color: rgba(66,66,66,1);
  background-color: #fdfdfd;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: 30px / 2;
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Lists
 */
ul, ol {
  margin-left: 30px;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Links
 */
a {
  color: hsl(356, 68%, 41%);
  text-decoration: none;

  &:hover {
    color: hsl(356, 100%, 20%);
    text-decoration: none;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{825px} - (#{30px} * 2));
  max-width:         calc(#{825px} - (#{30px} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  @extend %clearfix;

  @include media-query(820px) {
    max-width: -webkit-calc(#{825px} - (#{30px}));
    max-width:         calc(#{825px} - (#{30px}));
    padding-right: 30px / 2;
    padding-left: 30px / 2;
  }
}

::selection {
  background: #F09499;
}

::-moz-selection {
  background: #F09499;
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@charset "utf-8";
